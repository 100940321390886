/* .ProyectsView {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.ProyectsView img {
    width: 80%;
    height: 80%;
    object-fit: fill;
} */

.ProyectsView {
    display: flex;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}


@media screen and (max-width: 768px) {
    .ProyectsView {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 5rem 0;
    }

    .ProyectsView:nth-child(1) {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .ProyectsView {
        padding: 5rem 0;
    }
}

.ProyectsView img {
    width: 100%;
    margin-bottom: 1rem;
}