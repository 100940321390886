.navbar input[type="checkbox"],
.navbar .hamburger-lines {
    display: none;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


.bigLogo {
    margin-top: 1rem;
    width: 45%;
}


.navbar {
    position: relative;
    font-size: calc(10px + 2vmin);
    width: 100%;
    background: #fff;
    color: #000;
    height: 50px;
    z-index: 12;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1.5s;
    animation-delay: 0.75s;
    animation-fill-mode: forwards;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
}

.menu-items {
    order: 2;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}

.menu-items li {
    list-style: none;
    font-size: 1.5rem;
}

.menu-items a {
    text-decoration: none;
    color: #000;
    transition: color 0.3s ease-in-out;
}

.menu-items a:hover {
    color: #a5e7e7;
    transition: color 0.3s ease-in-out;
}

@media screen and (min-width: 761px) and (max-width:1080px) {
    .menu-items a {
        font-size: 1rem;
    }
}

@media (max-width: 760px) {
    .navbar {
        opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
        display: block;
    }

    .bigLogo {
        display: none;
    }

    .navbar-container {
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: -4rem;
        z-index: 5;
        opacity: 0;
    }

    .navbar-container .hamburger-lines {
        display: block;
        height: 25px;
        width: 35px;
        position: absolute;
        top: 17px;
        margin-top: 1rem;
        left: -4rem;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }

    .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
        padding-top: 100px;
        background: #fff;
        height: 100vh;
        max-width: 300px;
        transform: translate(-200%);
        display: flex;
        flex-direction: column;
        margin-left: -80px;
        padding-left: 20px;
        transition: transform 0.5s ease-in-out;
        box-shadow: 5px 0px 10px 0px #aaa;
    }

    .navbar .menu-items li {
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
        font-weight: 500;
    }

    .logo {
        position: absolute;
        left: -10rem;
        width: 100%;
    }

    .navbar-container input[type="checkbox"]:checked~.menu-items {
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-35deg);
    }
}

@media (max-width: 500px) {
    html {
        font-size: 65%;
    }

    .navbar .menu-items li {
        font-size: 1.6rem;
    }

    .logo {
        position: absolute;
        top: 15px;
        left: 13rem;
        width: 60%;
    }

    .navbar .menu-items li {
        margin-bottom: 2.5rem;
        font-size: 1.8rem;
        font-weight: 500;
    }
}

@media (orientation: landscape) and (max-height: 500px) {
    .showcase-area {
        height: 50vmax;
    }
}

@media (min-width: 768px) {
    .logo {
        display: none;
    }
}