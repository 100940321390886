.servicesView {
    margin-top: 3rem;
    display: grid;
    gap: 1rem;
    justify-content: center;
    grid-template-columns: 1fr;
}


@media screen and (min-width: 768px) {
    .servicesView {
        gap: 0;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1200px) {
    .servicesView {
        gap: 0;
        grid-template-columns: repeat(3, 1fr);

    }
}