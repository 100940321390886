.usView {
    margin-top: 3rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    justify-content: center;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;

}

.usView__title {
    font-size: 2.5rem;
}

.usView__p {
    margin-top: 2rem;
    margin-bottom: 10rem;
    text-align: center;
    line-height: 2.2rem;
}

.usView img {
    width: 100%;
}