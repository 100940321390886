.service {
    max-height: 60rem;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.service .color {
    height: 100%;
    background-color: var(--blue);
    color: white;
    display: grid;
    grid-template-rows: 1fr 2fr;
}

.service .colorGrey {
    height: 100%;
    background-color: var(--light-grey);
    display: grid;
    grid-template-rows: 1fr 2fr;
}

.service .serviceText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.service img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.newService {
    margin-top: 3rem;
    width: 100%;
    height: 40rem;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.newService .color {
    height: 100%;
    background-color: var(--blue);
    display: grid;
    grid-template-rows: 1fr 2fr;
}

.newService .serviceImg {
    width: 100%;
    height: 90%;
    object-fit: fill;
}

.newService .serviceText {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 3rem;
    color: white;
}

.newService .serviceText .serviceText__link {
    color: white;
}