body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DIN Next Rounded LT Pro Bold';
  src: url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Bold.woff2') format("woff2"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Bold.woff') format("woff"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Bold.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded LT Pro Medium';
  src: url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Medium.woff2') format("woff2"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Medium.woff') format("woff"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Medium.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded LT Pro Light';
  src: url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Light.woff2') format("woff2"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Light.woff') format("woff"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Light.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded LT Pro Regular';
  src: url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Regular.woff2') format("woff2"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Regular.woff') format("woff"),
    url('/public/fonts/DINNextRoundedLTPro/DINNextLTPro-Regular.ttf') format("truetype");
  font-display: swap;
}

h2,
h3 {
  font-family: 'DIN Next Rounded LT Pro Bold', Arial, Helvetica, sans-serif;
  margin: 0;
  color: var(--blue);
  font-weight: bold;
}

h4 {
  font-family: 'DIN Next Rounded LT Pro Bold', Arial, Helvetica, sans-serif;

  font-weight: bold;
}

h5 {
  margin: 0;
  font-family: 'DIN Next Rounded LT Pro Medium', Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 770px) {

  h2,
  h3 {
    font-size: 3.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {

  h2,
  h3 {
    font-size: 2.5rem;

  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }
}

a {
  font-family: 'DIN Next Rounded LT Pro Medium';
}

h6 {
  font-family: 'DIN Next Rounded LT Pro Medium';
}

p {
  font-family: 'DIN Next Rounded LT Pro Regular';
  font-size: large;
}

button {
  border-color: var(--blue);
  background-color: var(--blue);
  color: var(--white);
  border-radius: 2rem;
  cursor: pointer;
  width: 10rem;
  height: 3rem;
  font-family: 'DIN Next Rounded LT Pro Regular';
}

.buttonGrey {
  border-color: var(--light-grey);
  background-color: var(--light-grey);
  color: var(--blue);
  border-radius: 2rem;
  cursor: pointer;
  width: 10rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-family: 'DIN Next Rounded LT Pro Regular';
}

.buttonBlue {

  border-color: var(--blue);
  background-color: var(--blue);
  color: var(--white);
  border-radius: 2rem;
  text-decoration: none;
  cursor: pointer;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 3rem;
  font-family: 'DIN Next Rounded LT Pro Regular';

}

@keyframes aparicion {
  0% {
    opacity: 0;
    transform: scale(2) translateY(75px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

Iframe {
  width: 100%;
  height: 30rem;
}

:root {
  --blue: #213059;
  --acent-blue: #a5e7e7;
  --red: #d31e1e;
  --white: #FFFFFF;
  --black: #000000;
  --grey: #939496;
  --dark-grey: #717171;
  --light-grey: #eeeeee;
}