.contactUsForm .contactus {
    background-color: var(--light-grey);

}

.contactUsForm .contactus .contactusText {
    display: flex;
    padding: 3rem;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 355px) {
    .contactUsForm .contactus {
        padding: 0;
    }
}

@media screen and (min-width: 356px) {
    .contactUsForm .contactus {
        padding: 0 1rem;
    }
}

.contactUsForm .contactus .contactusText .contactusTwoText {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contactUsForm .contactus .contactusText .contactusTwoText span {
    width: 7rem;
}

.contactUsForm .contactus .button {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}