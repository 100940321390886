.App-header {

  font-size: calc(10px + 2vmin);
}

.App-header img {
  width: 40%;
}

.App-header nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.App-header .navLink {
  text-decoration: none;
  color: black;
  cursor: pointer;
}