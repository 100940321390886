.HomeView {
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1.3s;
    animation-delay: 0.75s;
    animation-fill-mode: forwards;
}

.HomeView .products {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
}

.HomeView .products img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.HomeView .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 5rem 0;
}

.HomeView .ourHistory {
    background-color: var(--light-grey);
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.HomeView .ourHistory img {
    width: 100%;
}


.HomeView .ourHistory .ourHistoryColumn .ourHistoryText {
    padding: 3rem;
    text-align: left;
}

.HomeView .map {
    margin-top: 2rem;
    display: grid;
}


.HomeView .services {
    margin-top: 3rem;
    display: grid;
    gap: 1rem;
    justify-content: center;
    grid-template-columns: 1fr;
}

@media screen and (min-width: 1200px) {
    .HomeView .services {
        gap: 0;
        grid-template-columns: repeat(3, 1fr);

    }
}


@media screen and (min-width: 768px) {
    .HomeView .ourHistory .ourHistoryColumn {
        flex: 1;
    }

}


@keyframes aparicion {
    0% {
        opacity: 0;
        transform: scale(2) translateY(75px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}