.contactUsView {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 0;
    animation-name: aparicion;
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 768px) {
    .contactUsView {
        margin-top: 3rem;
        padding: 0rem;
    }
}

@media screen and (min-width: 768px) {
    .contactUsView {
        padding: 3rem;
    }
}